import Vue from "vue";
import VueRouter from "vue-router";

//  Navbar
import MainNavbar from "../layout/MainNavbar.vue";
import MainFooter from "../layout/MainFooter.vue";

Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    name: "Index",
    category: null,
    components: {
      default: () => import('../pages/Index.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 40, type:'primary', navbarTransparent: true }
    }
  },
  // {
  //   path: "/#star",
  //   name: "애니팡페스타",
  //   category: 'star',
  //   // components: {
  //   //   default: () => import('../pages/games/Anipang2.vue'),
  //   //   header: MainNavbar,
  //   //   footer: MainFooter
  //   // },
  //   // props: {
  //   //   header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
  //   // }
  // },
  // {
  //   path: "/#series",
  //   name: "애니팡시리즈",
  //   category: 'series',
  //   // components: {
  //   //   default: () => import('../pages/games/Anipang2.vue'),
  //   //   header: MainNavbar,
  //   //   footer: MainFooter
  //   // },
  //   // props: {
  //   //   header: { colorOnScroll: 100, type:'white', navbarTransparent: true }
  //   // }
  // },
  // {
  //   path: "/#media",
  //   name: "미디어",
  //   category: 'media',
  //   // components: {
  //   //   default: () => import('../pages/games/Anipang2.vue'),
  //   //   header: MainNavbar,
  //   //   footer: MainFooter
  //   // },
  //   // props: {
  //   //   header: { colorOnScroll: 100, type:'anipang', navbarTransparent: true }
  //   // }
  // },
  {
    path: "/games/anipnag2",
    name: "애니팡2",
    category: 'Anipnag2',
    components: {
      default: () => import('../pages/games/Anipang2.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'primary', navbarTransparent: true }
    }
  },
  // {
  //   path: "/games/anipnag2emoticon",
  //   name: "애니팡2 이모티콘",
  //   category: 'Anipnag2',
  //   components: {
  //     default: () => import('../pages/games/Anipang2Emoticon.vue'),
  //     header: MainNavbar,
  //     footer: MainFooter
  //   },
  //   props: {
  //     header: { colorOnScroll: 100, type:'primary', navbarTransparent: true }
  //   }
  // },
  {
    path: "/games/anipnag3",
    name: "애니팡3",
    category: 'Anipang3',
    components: {
      default: () => import('../pages/games/Anipang3.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'primary', navbarTransparent: true }
    }
  },
  // {
  //   path: "/games/anipnag3emoticon",
  //   name: "애니팡3 이모티콘",
  //   category: 'Anipang3',
  //   components: {
  //     default: () => import('../pages/games/Anipang3Emoticon.vue'),
  //     header: MainNavbar,
  //     footer: MainFooter
  //   },
  //   props: {
  //     header: { colorOnScroll: 100, type:'primary', navbarTransparent: true }
  //   }
  // },
  {
    path: "/games/anipnag4",
    name: "애니팡4",
    category: 'Anipang4',
    components: {
      default: () => import('../pages/games/Anipang4.vue'),
      header: MainNavbar,
      footer: MainFooter
    },
    props: {
      header: { colorOnScroll: 100, type:'primary', navbarTransparent: true }
    }
  },
  // {
  //   path: "/games/anipnag4emoticon",
  //   name: "애니팡4 이모티콘",
  //   category: 'Anipang4',
  //   components: {
  //     default: () => import('../pages/games/Anipang4Emoticon.vue'),
  //     header: MainNavbar,
  //     footer: MainFooter
  //   },
  //   props: {
  //     header: { colorOnScroll: 100, type:'primary', navbarTransparent: true }
  //   }
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if ( to.hash ) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    }
  }
});

export default router