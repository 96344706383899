<template>
  <footer
    class="footer footer-white footer-big"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <br>
      <div class="link-button md-layout">
        <div class="md-layout-item md-medium-size-33 md-small-size-100">
          <a href="https://anipang2.onelink.me/KXv0/a270teei" target="_blank"><img :src="img.button.link1" alt="애니팡2"/></a>
        </div>
        <div class="md-layout-item md-medium-size-33 md-small-size-100">
          <a href="https://anipang3.onelink.me/BAqa/z23jht36" target="_blank"><img :src="img.button.link2" alt="애니팡3"/></a>
        </div>
        <div class="md-layout-item md-medium-size-33 md-small-size-100">
          <a href="https://ani4.onelink.me/k8vU/ce8xpux7" target="_blank"><img :src="img.button.link3" alt="애니팡4"/></a>
        </div>
      </div>

      <div class="logo">
        <router-link to="/"
                     class="footer-brand">
          <img :src="img.logo" alt="WEMADEPLAY_CI"/>
        </router-link>
      </div>
      <nav class="company-link">
        <ul>
          <li>
            <a href="https://corp.wemadeplay.com/policies" target="_blank">
              게임서비스 이용약관
            </a>
          </li>
          <li>
            <a href="https://corp.wemadeplay.com/privacy" target="_blank">
              개인정보 처리방침
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        © Wemade Play Co., Ltd. All Rights Reserved.
      </div>
    </div>

  </footer>
</template>
<script>
  import { mapGetters } from "vuex";

  export default {
    props: {
      backgroundColor: String,
      type: String,
      size: String
    },
    computed: {
      ...mapGetters([
        "resource",
      ]),
    },
    mounted() {
      const routes =  this.$router.options.routes;
      const category = {};

      routes.forEach((item) => {
        if (item.category && !{}.hasOwnProperty.call(category, item.category)) {
          category[item.category] = [];
        }
        if (item.category) {
          category[item.category].push(item)
        }

      });
      this.menus = category;
      document.addEventListener("scroll", this.scrollListener);
    },
    data() {
      return {
        img: {
          logo: require("@/assets/img/pages/WEMADEPLAY_CI.png"),
          button: {
            link1: require("@/assets/img/pages/main_bt_anipang2.png"),
            link2: require("@/assets/img/pages/main_bt_anipang3.png"),
            link3: require("@/assets/img/pages/main_bt_anipang4.png"),
          },
        },
      };
    }
  };
</script>

<style lang="scss">
  .footer {

    .footer-brand {
      margin-left: 15px !important;
    }
    .link-button {
      max-width: 600px;
      margin: 0 auto 50px;
    }

    .logo {
      margin-bottom: 30px;
      img {
        max-width: 300px;
      }
    }

    .company-link {
      ul {
        li {
          a {
            padding: 0 20px;
          }
        }
      }
    }

    .copyright {
      padding: 10px 0;
      font-weight: 100;

    }
  }

</style>
