<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">
          <router-link to="/"
                       class="md-button md-button-link md-white md-simple dropdown-toggle">
            애니팡 시리즈
          </router-link>
        </h3>
        <!--<HorizontalNav v-if="$route.path === path"/>-->
      </div>
      <div class="md-toolbar-section-end">
        <div class="md-toolbar-toggle"
             :class="{ toggled: toggledClass }">
          <md-button class="md-just-icon md-simple"
                     @click="toggleNavbarMobile()">
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </md-button>
        </div>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="true">
              <!--<md-list id="cd-horizontal-nav">-->
                <!--<li class="md-list-item">-->
                  <!--<router-link to="/"-->
                               <!--@click.native="moveToLink()"-->
                               <!--class="md-button md-button-link md-white md-simple dropdown-toggle">-->
                    <!--<div class="md-list-item-content">-->
                      <!--애니팡 시리즈-->
                    <!--</div>-->
                  <!--</router-link>-->
                <!--</li>-->
              <!--</md-list>-->
              <!--<HorizontalNav v-if="$route.path === path"/>-->
            </mobile-menu>

            <md-list>
              <template v-for="shortcut in shortcuts">
                <li class="md-list-item" :key="shortcut.id">
                  <a class="md-list-item-router md-list-item-container md-button-clean">
                    <div class="md-list-item-content">
                      <a href="javascript:void(0)"
                         data-number="1"
                         @click="moveToLink(shortcut.id)"
                         class="md-button md-button-link md-white md-simple dropdown-toggle">
                        <!--@click.native="scrollToElement(shortcut.id)"-->
                        <div>
                          {{shortcut.label}}
                        </div>
                      </a>
                    </div>
                  </a>
                </li>
              </template>
              <!--<template v-for="(route, key) in menus">-->
                <!--<li class="md-list-item" :key="key">-->
                  <!--<a class="md-list-item-router md-list-item-container md-button-clean">-->
                    <!--<div class="md-list-item-content">-->
                      <!--<router-link :to="`${route[0].path}`"-->
                                   <!--@click.native="moveToLink()"-->
                                   <!--class="md-button md-button-link md-white md-simple dropdown-toggle">-->
                        <!--<div>{{route[0].name}}</div>-->
                      <!--</router-link>-->
                    <!--</div>-->
                  <!--</a>-->
                <!--</li>-->
              <!--</template>-->
              <li class="md-list-item">
                <a class="md-list-item-router md-list-item-container md-button-clean">
                  <div class="kakao-button-wrap md-list-item-content">
                    <a href="https://pf.kakao.com/_xoxgxdxob"
                       target="_blank"
                       data-number="1"
                       @click="moveToLink()">
                      <img :src="kakao_ch" alt="카카오톡채널"/>
                    </a>
                  </div>
                </a>
              </li>
              <li class="md-list-item">
                <a class="md-list-item-router md-list-item-container md-button-clean">
                  <div class="kakao-button-wrap md-list-item-content">
                    <a href="https://cafe.naver.com/wemadeplay"
                       target="_blank"
                       data-number="1"
                       @click="moveToLink()">
                      <img :src="naver_cafe" alt="naver_cafe"/>
                    </a>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>

  </md-toolbar>
</template>

<script>
  import { mapGetters } from "vuex";
  import MobileMenu from "@/layout/MobileMenu";
  // import HorizontalNav from "@/container/HorizontalNav";
  import Mixins from "@/plugins/basicMixins";

  export default {
    components: {
      MobileMenu,
      // HorizontalNav,
    },
    mixins: [Mixins.HorizontalNav],
    props: {
      type: {
        type: String,
        default: "white",
        validator(value) {
          return [
            "white",
            "default",
            "primary",
            "danger",
            "success",
            "warning",
            "info",
            "dark",
            "anipang",
          ].includes(value);
        }
      },
      colorOnScroll: {
        type: Number,
        default: 0
      },
      navbarTransparent: {
        type: Boolean,
        default: false
      }
    },
    // mixins: [Mixins.HeaderImage],
    computed: {
      ...mapGetters([
        "resource",
      ]),
    },
    mounted() {
      this.selectLocale = this.locale;
      const routes =  this.$router.options.routes;
      const category = {};

      routes.forEach((item) => {
        if (item.category && !{}.hasOwnProperty.call(category, item.category)) {
          category[item.category] = [];
        }
        if (item.category) {
          category[item.category].push(item);
        }

      });
      this.menus = category;
      // document.addEventListener("scroll", this.scrollListener);
    },
    beforeDestroy() {
      // document.removeEventListener("scroll", this.scrollListener);
    },
    watch: {
      locale(value) {
        this.selectLocale = value;
      }
    },
    methods: {

      // ////////////////////////////////////////
      bodyClick() {
        let bodyClick = document.getElementById("bodyClick");
        let body = document.querySelector("body");

        if (bodyClick === null) {
          let elem = document.createElement("div");
          elem.setAttribute("id", "bodyClick");
          body.appendChild(elem);

          bodyClick = document.getElementById("bodyClick");
          bodyClick.addEventListener("click", this.toggleNavbarMobile);
          body.classList.add("noneScroll");
        } else {
          bodyClick.remove();
          body.classList.remove("noneScroll");
        }
      },

      bodyClickIcon() {
        let bodyClickIcon = document.getElementById("bodyClickIcon");
        let body = document.querySelector("body");

        if (bodyClickIcon === null) {
          let elem = document.createElement("div");

          elem.setAttribute("id", "bodyClickIcon");
          body.appendChild(elem);
          bodyClickIcon = document.getElementById("bodyClickIcon");
          bodyClickIcon.addEventListener("click", this.toggleNavbarMobile);
          body.classList.add("noneScroll");
        } else {
          bodyClickIcon.remove();
          body.classList.remove("noneScroll");
        }
      },

      toggleNavbarMobile() {
        this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
        this.toggledClass = !this.toggledClass;
        this.bodyClickIcon();
      },

      moveToLink(id) {
        if (this.NavbarStore.showNavbar) {
          this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
          this.toggledClass = !this.toggledClass;
          this.bodyClickIcon();
        }
        if (id) {
          if (this.$route.path === '/') {
            this.scrollToElement(id);
          } else {
            this.$router.push(`/#${id}`);
          }
        }
      },

      handleScroll() {
        let scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
        let navbarColor = document.getElementById("toolbar");

        if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
          this.extraNavClasses = `md-${this.type}`;
          navbarColor.classList.remove("md-transparent");
        } else {
          if (this.extraNavClasses) {
            this.extraNavClasses = "";
            navbarColor.classList.add("md-transparent");
          }
        }
      },

      scrollListener() {
        window.resizeThrottler(this.handleScroll);
      },

    },
    data() {
      return {
        menus: {},
        path: '/',
        extraNavClasses: "",
        toggledClass: false,
        selectLocale: '', // navigator.language.split('-')[0],
        shortcuts: [
          // {
          //   id: 'shortcut-star',
          //   label: '애니팡 페스타'
          // },
          {
            id: 'shortcut-series',
            label: '시리즈 소개'
          },
          {
            id: 'shortcut-media',
            label: '미디어'
          },
        ],

        kakao_ch: require("@/assets/img/pages/kakao_ch.png"),
        naver_cafe: require("@/assets/img/pages/main_bt_naver-cafe.png"),
      };
    },
  };
</script>
<style lang="scss">
  .kakao-button-wrap {
    justify-content: center !important;
    margin: 0 5px;

    img {
      width: 30px;
    }
  }
</style>
