import Vue from 'vue';
import Vuex from 'vuex';
import CreatePersistedState from 'vuex-persistedstate';

import resource from './spec/resource';
import basic from './basic';

Vue.use(Vuex);


let resizeTimeout;
window.resizeThrottler = (actualResizeHandler) => {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 66);
  }
}


const vuexSessionPersisted = new CreatePersistedState({
  key: 'anipang-club-session',
  storage: window.sessionStorage,
  reducer: states => ({
    member: states.member,
    ordering: states.ordering,
  }),
});

export default new Vuex.Store({
  state: {
    resource,
    ...basic.state,
  },

  getters: {
    resource: state => state.resource,
    ...basic.getters,
  },

  actions: {
    ...basic.actions,

    INITIALIZE_APP() {
      // this.dispatch('GET_LANGUAGE_DATA');
      // this.dispatch('GET_LOGIN_USER_DATA');
    },
  },

  mutations: {
    ...basic.mutations,
  },

  plugins: [vuexSessionPersisted],
});
