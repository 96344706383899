const Resource = () => {
  const navigatorUserAgent = navigator.userAgent.toLowerCase();

  // --> todo : local test code
  if (window.__ANIPANG_CLUB_SERVICE_ENV__.resource.isDomestic === '##isDomestic') {
    window.__ANIPANG_CLUB_SERVICE_ENV__.resource.isDomestic = 'true';
  }
  if (window.__ANIPANG_CLUB_SERVICE_ENV__.resource.service === '##service') {
    const service = 'anypang series';

    window.__ANIPANG_CLUB_SERVICE_ENV__.resource.service = service;
    window.__ANIPANG_CLUB_SERVICE_ENV__.resource.baseUrl = `http://${location.hostname}:8090`;
  }
  if (window.__ANIPANG_CLUB_SERVICE_ENV__.resource.env === '##env') {
    const key = 'development';
    const env = {
      production: 'production',
      development: 'development',
    };
    window.__ANIPANG_CLUB_SERVICE_ENV__.resource.env = env[key];
  }

  if (window.__ANIPANG_CLUB_SERVICE_ENV__.resource.userAgent === '##userAgent') {
    let userAgent = 'other';

    if ( navigatorUserAgent.indexOf('android') > -1) {
      userAgent = 'android';
    } else if ( navigatorUserAgent.indexOf("iphone") > -1||navigatorUserAgent.indexOf("ipad") > -1||navigatorUserAgent.indexOf("ipod") > -1 ) {
      userAgent = 'ios';
    }
    window.__ANIPANG_CLUB_SERVICE_ENV__.resource.userAgent = userAgent;
  }

  if (window.__ANIPANG_CLUB_SERVICE_ENV__.resource.isMobile === '##isMobile') {
    window.__ANIPANG_CLUB_SERVICE_ENV__.resource.isMobile = (/iphone|ipad|ipod|android/i.test(navigatorUserAgent));
  }

  window.__ANIPANG_CLUB_SERVICE_ENV__.resource.isDomestic = JSON.parse(window.__ANIPANG_CLUB_SERVICE_ENV__.resource.isDomestic);
  // <-- todo : local test code
  return window.__ANIPANG_CLUB_SERVICE_ENV__.resource;
};

export default Resource();
